.construction-style {
    .homepage-caption-slider {
        @media @lg {
            .caption-slider {
                .slick-arrow {
                    transform: translateY(100px);
                }

                .block-caption-slider {
                    .overlay {
                        padding-top : 200px;
                    }
                }
            }
        }
    }
}